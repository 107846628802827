import React from 'react';
import './App.css';
import logo from './logo.svg'; 

function App() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="min-h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white">
      <header className="flex justify-between items-center p-6">
        <img src={logo} alt="Tiempra Logo" className="h-10" />
        <nav className="flex space-x-4">
          <a href="#home" className="hover:underline">Home</a>
          <a href="#events" className="hover:underline">Events</a>
          <a href="#rsvp" className="hover:underline">RSVP</a>
          <a href="#contact" className="hover:underline">Contact</a>
        </nav>
        <a href="#login" className="bg-white text-indigo-600 py-2 px-4 rounded-full">Login</a>
      </header>
      <main className="p-6 text-center">
        <h1 className="text-5xl font-bold mb-2">Tiempra: Your Campus Connection</h1>
        <p className="mb-6">Exclusive access to the best college events, from Greek life to the hottest clubs on campus.</p>
        <form className="w-full max-w-lg mx-auto flex justify-center">
          <input
            type="email"
            placeholder="Enter your email for updates"
            className="w-full p-3 rounded-l-lg"
          />
          <button
            type="submit"
            className="bg-white text-indigo-600 py-3 px-6 rounded-r-lg hover:bg-gray-100"
          >
            Join Us
          </button>
        </form>
        <p className="mt-4">Join your campus on Tiempra</p>
      </main>
      <section className="p-6">
        <h2 className="text-3xl font-semibold">What We Offer</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          <div className="bg-gradient-to-tr from-indigo-300 to-pink-300 rounded-lg p-6 shadow-lg">
            <h3 className="text-xl font-semibold mb-2">Exclusive Event Access</h3>
            <p>Get front-row tickets to the most sought-after college events and parties. Whether it's the biggest frat party or a sophisticated jazz night, Tiempra has you covered.</p>
          </div>
          <div className="bg-gradient-to-tr from-indigo-300 to-pink-300 rounded-lg p-6 shadow-lg">
            <h3 className="text-xl font-semibold mb-2">Easy RSVP and Check-in</h3>
            <p>Skip the line with Tiempra's hassle-free RSVP system and digital check-in. Your phone is your ticket into the world of exclusive campus life.</p>
          </div>
          <div className="bg-gradient-to-tr from-indigo-300 to-pink-300 rounded-lg p-6 shadow-lg">
            <h3 className="text-xl font-semibold mb-2">Greek Life Networking</h3>
            <p>Connect with fraternities and sororities for networking opportunities and exclusive Greek life events tailored to your interests.</p>
          </div>
          <div className="bg-gradient-to-tr from-indigo-300 to-pink-300 rounded-lg p-6 shadow-lg">
            <h3 className="text-xl font-semibold mb-2">Club Events & Workshops</h3>
            <p>Discover and sign up for workshops, seminars, and club meetings. Expand your horizons with a wide range of educational and extracurricular activities.</p>
          </div>
        </div>
      </section>
      <footer className=" p-6 text-center">
        <p>© {currentYear} Tiempra. All about your campus life.</p>
        {/* <p>Powered by the energy of college spirit.</p> */}
      </footer>
    </div>
  );
}

export default App;
